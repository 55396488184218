.filter-bar-category-item {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--primary-dark) !important;
  }

  .filter-bar-category-item-title {
    cursor: pointer;
    font-size: .9rem;
    user-select: none;
    border-top: 1px solid #00000012;
    padding: 0.7rem 0;

    mat-checkbox {
      margin-right: 2rem;
    }

    .badge {
      background: var(--secondary-light);
      color: var(--primary-dark);
      font-weight: 600;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 1rem;
    }
  }

  .my-constructions {
    margin-x: -1rem;
  }

  .mat-slide-toggle-thumb {
    background: var(--white);
  }

  .mat-slide-toggle-bar {
    background: var(--grey-dark);
  }
}
