@import "styles/responsive";

.filter-bar {
  .filter-bar-header {
    color: var(--accent);
    font-size: 1rem;
    font-weight: bold;
  }

  .filter-bar-footer {
    border-top: 1px solid #00000012;
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    background: var(--white);
  }
}
