packex-filter-chip {
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: var(--primary-dark) !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    color: var(--primary);
    background: var(--secondary-light);
    &:hover {
      background: var(--overlay);
    }

    &:focus {
      background: var(--overlay-dark);
    }

    .mat-mdc-chip-remove {
      opacity: 1 !important;
    }
  }

  .mdc-evolution-chip-set__chips {
    align-items: center;
  }

  .deactivate-all {
    cursor: pointer;
    color: var(--primary);
    text-decoration: underline;
    margin-left: 10px;
    &:hover, &:focus {
      color: var(--accent);
    }
  }
}
