@import "styles/responsive";

packex-construction-card {
  .mat-mdc-card {
    color: var(--primary);
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    packex-label {
      .label-icon {
        width: 2rem !important;
        height: 2rem !important;
        transition: all .3s ease;
      }

      img {
        transition: all .3s ease;
      }
    }

    &:hover {
      .product-image img {
        width: 105%;
      }

      packex-label {
        .label-icon {
          width: 3rem !important;
          height: 3rem !important;
        }

        &.config-icon {
          img {
            width: 2rem;
            height: 2rem;
            transform: rotate(-180deg);
          }
        }
      }
    }

    .clickable-area {
      cursor: pointer;

      &:hover {
        packex-label {

          ::ng-deep {

            span {
              display: block;
            }
          }
        }
      }
    }

    .mat-mdc-card-content {
      padding-left: 0;
    }
  }

  .product-configure {
    position: absolute;
    top: 26px;
    right: 30px;
    z-index: 1;

    .product-more-information-close {
      font-size: 25px;
      cursor: pointer;
    }
  }

  .product-image {
    background: #fcfcfc;
    height: 19rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      transition: all .3s ease;
    }
  }

  .product-details {
    padding: 1rem;
    .mat-mdc-card-title {
      font-size: 1rem;
      line-height: 20px;
    }

    .mat-mdc-card-subtitle {
      color: var(--primary-200);
      font-size: 12px;
    }

  }

  .product-more-information {
    margin: auto 1rem 0 1rem;
    padding: 0.5rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    .product-attributes {
      margin-top: 1rem;
      .attribute {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          font-weight: 600;
        }
      }
    }
  }
}
